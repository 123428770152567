<template>
    <div class="carbonMarket">
        <div class="title">碳行情（全国市场）</div>
        <div class="echart-wrapper">
            <div class="echart-ref" ref="echart_ref"></div>
            <!-- <div class="echart-ref" ref="echart_ref_combine"></div> -->
        </div>

        <el-table ref="multipleTable" :data="tableData" style="margin-left: 150px;">
            <!--  fontFamily: 'Arial Normal, Arial, sans-serif', -->
            <!-- 日期 -->
            <el-table-column prop="current_date" class="date" label="日期" align="left" width="150px"
                class-name="current_date" header-align="left">
            </el-table-column>
            <el-table-column prop="end_price" label="最新价" width="150px" align="right" class-name="end_price"
                header-align="right">
            </el-table-column>
            <!-- 涨跌 -->
            <el-table-column prop="percent" label="涨跌" width="150px" class-name="percent" header-align="right"
                align="right">
                <template slot-scope="scope">
                    <span v-if="scope.row.percent.startsWith('-')" style="color: #07C160">
                        {{ scope.row.percent }}
                    </span>
                    <span v-else-if="/^0.0+%$/.test(scope.row.percent)">
                        {{ scope.row.percent }}
                    </span>
                    <span v-else="scope.row.percent.startsWith('+')" style="color: #ff2832">
                        {{ scope.row.percent }}
                    </span>
                </template>
                <span> {{}} </span>
            </el-table-column>
            <!-- 成交量 -->
            <el-table-column prop="amount" label=" 成交量" width="150px" class-name="amount" header-align="right"
                align="right">
            </el-table-column>
            <!-- 成交额 -->
            <el-table-column prop="total" class-name="total" label=" 成交额" width="150px" align="right" header-align="right">
            </el-table-column>
            <!-- 振幅 -->
            <el-table-column prop="amplitude" class-name="amplitude" label=" 振幅" width="150px" header-align="right"
                align="right">
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import * as echarts from "echarts";
import { toFixed } from "../../utils/util";
export default {
    name: "CarbonMarket",
    data() {
        return {
            tableData: [],
            tableData_Expand: [],
            newlist: [],
            newdate_list: [],
            newprice_list: [],
            newamount_list: [],
            newtotal_list: [],
            tableShow: true,
            tableData: [],
            citys: [{ name: "全国", value: "china" }],
            active: "hubei",
            // k || trend
            activeSwitch: "k",
            ref: null,
            instance: null,
            combine_ref: null,
            combine_instance: null,
            options: null,
            combine_options: null,
            date_list: [],
            price_list: [],
            amount_list: [],
            total_list: [],
            resizing: null,
            form: {
                time: "今天",
                open: "",
                close: "",
                low: "",
                high: "",
                amount: "",
                total: "",
            },
            current_dates: [],
            china_prices: [],
            hubei_prices: [],
            shenzhen_prices: [],
            guangdong_prices: [],
            tianjin_prices: [],
            shanghai_prices: [],
            beijing_prices: [],
            chongqin_prices: [],
            fujian_prices: [],
        };
    },
    created() {
        this.getTableData()
    },
    activated() {
        console.log("CarbonMarket, activated");
        if (this.$route.query.all) {
            this.active = "china";
        } else {
            this.active = "hubei";
        }
        // this.activeSwitch = 'trend';
        this.handleSwitch("trend");
        window.addEventListener("resize", this.handleResize);
    },
    deactivated() {
        window.removeEventListener("resize", this.handleResize);
        this.activeSwitch = "";
    },
    methods: {
        // 拿到基础数据
        async getTableData(type) {
            return new Promise(resolve => {
                // http://192.168.23.15:8000/article/carbonpricelist
                this.api
                    .postFormAPISM(
                        {
                            ...this.pagination,
                        },
                        "/article/carbonpricelist"
                    )
                    .then((res) => {
                        console.log("getTableData", res.data);
                        this.tableData = res.data.data.daily_price_list;
                        this.pagination.page_count = res.data.data.pagination.page_count;
                        resolve(this.tableData[0])
                        // console.log(res.data.data.pagination.page_count,'res.data.data.pagination.page_count');
                        // console.log(
                        //   res.data.data.daily_price_list,
                        //   "res.data.data.daily_price_list"
                        // );
                    })
                    .catch((error) => {
                        console.log("error", error);
                    });
                // console.log(this.$store.state.oneNationalMarket,'kankanwo1');
                // this.tableData = this.$store.state.oneNationalMarket.daily_price_list;
                // this.pagination.page_count = this.$store.state.oneNationalMarket.pagination.page_count;
            })
        },
        startUsing() { },
        rowClick(val) {
            console.log("val", val);
        },
        initInstance() {
            let ref = this.$refs.echart_ref;
            this.ref = ref instanceof Array ? ref[0] : ref;
            this.instance = echarts.init(this.ref, null, { renderer: "svg" });
            let that = this;
            this.instance.on("showTip", function (params) { });
            this.instance.on("hideTip", function (params) { });
        },
        initTrendOption() {
            this.options = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        label: {
                            show: false,
                        },
                        lineStyle: {
                            type: "solid",
                        },
                        crossStyle: {
                            type: "solid",
                        },
                    },
                    formatter: (params) => {
                        let Marker =
                            '<span style="display:inline-block;margin-right:6px;border-radius:10px;width:10px;height:10px;background-color:#color;"></span>';
                        let str = "";
                        for (let i = 0; i < params.length; i++) {
                            let sub_str = "";
                            let color = params[i].color;
                            let seriesName = params[i].seriesName;
                            let marker = Marker.replace("#color", color);
                            let value = params[i].data;
                            if (typeof value === "number" && !isNaN(value)) {
                                value = toFixed(value, 2) + "元";
                            } else {
                                value = "-";
                            }
                            if (!i) {
                                sub_str += params[i].name + "<br>";
                            }
                            sub_str += marker;
                            sub_str += seriesName;
                            sub_str += " 收盘价: " + value;
                            if (i !== params.length - 1) {
                                sub_str += "<br>";
                            }
                            str += sub_str;
                        }
                        // console.log('tooltip', params);
                        return str;
                    },
                },
                legend: {
                    data: this.citys.map((item) => item.name),
                    // show: false,
                },
                grid: [
                    {
                        left: 80,
                        right: 35,
                        bottom: 40,
                        top: "10%",
                        height: "65%",
                    },
                ],
                xAxis: [
                    {
                        type: "category",
                        data: [],
                        boundaryGap: false,
                        axisLine: { onZero: false },
                        splitLine: { show: false },
                    },
                ],
                yAxis: [
                    {
                        axisLabel: { show: true },
                        axisLine: { show: false },
                        axisTick: { show: false },
                        splitLine: { show: true },
                        splitArea: {
                            show: false,
                        },
                    },
                ],
                dataZoom: [
                    {
                        type: "inside",
                        startValue: 999790,
                        endValue: 999999,
                    },
                    {
                        type: "slider",
                        startValue: 999790,
                        endValue: 999999,
                        top: "80%",
                    },
                ],
                series: [
                    {
                        name: "全国",
                        type: "line",
                        data: [],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 10,
                        showSymbol: false,
                        connectNulls: false,
                        itemStyle: {
                            color: "red",
                            shadowColor: "red",
                            shadowBlur: 15,
                        },
                    },
                    {
                        name: "湖北",
                        type: "line",
                        data: [],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 10,
                        showSymbol: false,
                        connectNulls: false,
                        itemStyle: {
                            color: "#91cc75",
                            shadowColor: "#91cc75",
                            shadowBlur: 15,
                        },
                    },
                    {
                        name: "深圳",
                        type: "line",
                        data: [],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 10,
                        showSymbol: false,
                        connectNulls: false,
                        itemStyle: {
                            color: "#fac858",
                            shadowColor: "#fac858",
                            shadowBlur: 15,
                            // color: upColor,
                            // color0: downColor,
                            // borderColor: undefined,
                            // borderColor0: undefined,
                        },
                    },
                    {
                        name: "广东",
                        type: "line",
                        data: [],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 10,
                        showSymbol: false,
                        connectNulls: false,
                        itemStyle: {
                            color: "#ee6666",
                            shadowColor: "#ee6666",
                            shadowBlur: 15,
                            // color: upColor,
                            // color0: downColor,
                            // borderColor: undefined,
                            // borderColor0: undefined,
                        },
                    },
                    {
                        name: "天津",
                        type: "line",
                        data: [],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 10,
                        showSymbol: false,
                        connectNulls: false,
                        itemStyle: {
                            color: "#73c0de",
                            shadowColor: "#73c0de",
                            shadowBlur: 15,
                            // color: upColor,
                            // color0: downColor,
                            // borderColor: undefined,
                            // borderColor0: undefined,
                        },
                    },
                    {
                        name: "上海",
                        type: "line",
                        data: [],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 10,
                        showSymbol: false,
                        connectNulls: false,
                        itemStyle: {
                            color: "#3ba272",
                            shadowColor: "#3ba272",
                            shadowBlur: 15,
                            // color: upColor,
                            // color0: downColor,
                            // borderColor: undefined,
                            // borderColor0: undefined,
                        },
                    },
                    {
                        name: "北京",
                        type: "line",
                        data: [],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 10,
                        showSymbol: false,
                        connectNulls: false,
                        itemStyle: {
                            color: "#fc8452",
                            shadowColor: "#fc8452",
                            shadowBlur: 15,
                        },
                    },
                    {
                        name: "重庆",
                        type: "line",
                        data: [],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 10,
                        showSymbol: false,
                        connectNulls: false,
                        itemStyle: {
                            color: "#9a60b4",
                            shadowColor: "#9a60b4",
                            shadowBlur: 15,
                        },
                    },
                    {
                        name: "福建",
                        type: "line",
                        data: [],
                        smooth: true,
                        symbol: "circle",
                        symbolSize: 10,
                        showSymbol: false,
                        connectNulls: false,
                        itemStyle: {
                            color: "#ea7ccc",
                            shadowColor: "#ea7ccc",
                            shadowBlur: 15,
                        },
                    },
                ],
            };
        },
        getTrendEchartsData() {
            // http://192.168.23.15:8000/prices/getcarbonpricetrend
            this.instance.showLoading({ text: "" });
            // this.combine_instance.showLoading();
            this.api
                .postFormAPISM(
                    {
                        limit: 999999,
                        send_time: "",
                    },
                    "/prices/getcarbonpricetrend"
                )
                .then((res) => {
                    let data = res.data.data;
                    let data_keys = Object.keys(data);
                    for (let i = 0; i < data_keys.length; i++) {
                        let temp = data[data_keys[i]].map((item) => {
                            if (
                                !item ||
                                item == "" ||
                                item == undefined ||
                                /^(-+|none)$/i.test(item)
                            ) {
                                return null;
                            } else {
                                return data_keys[i] == "current_dates"
                                    ? item
                                    : parseFloat(item);
                            }
                        });
                        this[data_keys[i]] = temp.reverse();
                    }
                    this.setTrendOption();
                })
                .catch((error) => {
                    console.log("getTrendEchartsData, error", error);
                });
        },
        setKOption() {
            this.options.dataZoom[0].startValue = this.date_list.length - 1;
            this.options.dataZoom[0].endValue = this.date_list.length - 50;
            this.options.dataZoom[1].startValue = this.date_list.length - 1;
            this.options.dataZoom[1].endValue = this.date_list.length - 50;
            //k线图
            let series_data = this.price_list.map((item) => {
                return item.map((Item) =>
                    /-/.test(Item) || !Item ? "-" : toFixed(parseFloat(Item), 2)
                );
            });
            // console.log('series_data', series_data);
            this.options.series[0].data = series_data;

            let series_data_amount = this.amount_list.map((item) => {
                return /-/.test(item) || !item ? "-" : toFixed(item.replace(",", ""));
            });
            this.options.series[1].data = series_data_amount;
            let city_name =
                this.citys[this.citys.findIndex((item) => item.value == this.active)]
                    .name;
            // this.options.series[0].name = city_name;
            // this.options.series[0].name = city_name;
            // this.options.legend.data = [city_name];
            this.options.xAxis[0].data = this.date_list;
            this.options.xAxis[1].data = this.date_list;
            // console.log('ref', this.instance);
            this.instance.setOption(this.options);
            this.instance.hideLoading();

            //组合图
        },
        setTrendOption() {
            this.options.xAxis[0].data = this.current_dates;
            for (let i = 0; i < this.citys.length; i++) {
                this.options.series[i].data = this[this.citys[i].value + "_prices"];
                // console.log('setTrendOption', this.citys[i].value ,this.options.series[i].data);
            }
            console.log("setTrendOption", this.options);
            this.instance.hideLoading();
            this.instance.setOption(this.options);
        },
        handleSelectCity(active) {
            if (this.activeSwitch != "k") return;
            this.active = active;
            this.initKOption();
            this.getKEchartsData();
        },
        handleSwitch(activeSwitch) {
            if (this.activeSwitch == activeSwitch) return;
            console.log("handleSwitch", activeSwitch);
            this.activeSwitch = activeSwitch;
            this.instance && this.instance.dispose();
            this.initInstance();
            if (this.activeSwitch == "k") {
                this.tableShow = false;
                this.initKOption();
                this.getKEchartsData();
                // console.log(this.form,'form');
            } else {
                this.tableShow = true;
                this.initTrendOption();
                this.getTrendEchartsData();
            }
        },
        handleResize() {
            this.resizing && window.clearTimeout(this.resizing);
            this.resizing = setTimeout(() => {
                console.log("handleResize");
                this.instance && this.instance.resize && this.instance.resize();
                this.resizing = null;
            }, 500);
        },
    },
};
</script>
<style lang="less">
.carbonMarket {
    flex-grow: 1;
    width: 1200px;
    margin: auto;
    padding: 20px 10px 0;
    display: flex;
    flex-flow: column nowrap;

    &>.echart-wrapper {
        flex-grow: 1;
        min-height: 700px;
        max-height: 700px;
        overflow: hidden;
        // display: flex;
        // flex-flow: column nowrap;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        grid-gap: 20px;

        &>.echart-ref {

            //   flex-grow: 1;
            //   height: 50%;
            &>div {
                &:first-child {
                    height: 100%;
                }
            }
        }
    }

}
</style>
